var exports = {};

exports = function (hljs) {
  return {
    keywords: "false int abstract private char boolean static null if for true " + "while long throw finally protected final return void enum else " + "break new catch byte super case short default double public try this switch " + "continue reverse firstfast firstonly forupdate nofetch sum avg minof maxof count " + "order group by asc desc index hint like dispaly edit client server ttsbegin " + "ttscommit str real date container anytype common div mod",
    contains: [hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE, hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE, hljs.C_NUMBER_MODE, {
      className: "meta",
      begin: "#",
      end: "$"
    }, {
      className: "class",
      beginKeywords: "class interface",
      end: "{",
      excludeEnd: true,
      illegal: ":",
      contains: [{
        beginKeywords: "extends implements"
      }, hljs.UNDERSCORE_TITLE_MODE]
    }]
  };
};

export default exports;